import React from 'react';
import { WhiteBoard } from '../../components/styles';
import { PageWrapper } from '../styles';
import isoData from '../../constants/pagesData/isoData';
import PDFViewer from '../../components/PDFViewer';

const ISOCert = ({t}) => {
    return (  
        <PageWrapper>
            <WhiteBoard>
                <p style={{fontSize : '0.8rem'}}>{t(isoData.text)}</p>
            </WhiteBoard>
            <PDFViewer url={isoData.file}/>
        </PageWrapper>
    );
}
 
export default ISOCert;