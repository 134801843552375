import React from 'react';
import { filter } from 'lodash';
import ProductList from '../components/Menu/ProductList';
import FadeIn from 'react-fade-in';
import { useParams } from 'react-router';
import styled from 'styled-components';

const CategoryProducts = ({ menu, t }) => {

    const {category} = useParams();
    const products = filter(menu, { 'tipoPlato': Number(category) })

    return (
        <CategoryProductsWrapper>
        <FadeIn>
            <ProductList product_list={products} hidePhoto t={t}/>
        </FadeIn>
        </CategoryProductsWrapper>
    )
};

const CategoryProductsWrapper = styled.div`
    position  : absolute;
    width : 100%;
    background-color : rgb(244, 246, 252);
    padding : 10px;
    top : 0;
    left : 0;
    z-index : 1;
    overflow : auto;
`;

export default CategoryProducts;