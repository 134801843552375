import EnvPol from '../../pages/EnvSubPages/EnvPol';
import ISOCert from '../../pages/EnvSubPages/ISOCert';
import WhatWeDo from '../../pages/EnvSubPages/WhatWeDo';
import WhatYouDo from '../../pages/EnvSubPages/WhatYouDo';

// images
import envpol from '../../assets/img/hotelImages/menuButtons/envpol/envpol.jpg';
import wedo from '../../assets/img/hotelImages/menuButtons/envpol/wedo.jpg';
import asguest from '../../assets/img/hotelImages/menuButtons/envpol/asguest.jpg';
import iso from '../../assets/img/hotelImages/menuButtons/envpol/iso.jpg';

const envPolData = [
  {
    title: 'Sostenibilidad de GPRO',
    url: '/envpolicy/policy',
    page: EnvPol,
    img: envpol,
  },
  {
    title: '¿Qué hacemos?',
    url: '/envpolicy/what-we-do',
    page: WhatWeDo,
    img: wedo,
  },
  {
    title: 'Certificación ISO 14001',
    url: '/envpolicy/iso',
    page: ISOCert,
    img: iso,
  },
  {
    title: '¿Qué puede hacer usted como cliente para ayudarnos?',
    url: '/envpolicy/what-you-do',
    page: WhatYouDo,
    img: asguest,
  },
];

export default envPolData;
