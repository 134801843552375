import React from 'react';

import confirmationData from '../../../constants/pagesData/confirmationData';
import { PageWrapper } from '../../styles';
import Card from '../../../components/Card';

const Confirmation = ({t}) => {
    return (  
        <PageWrapper>
            <Card data={confirmationData} t={t}/>
        </PageWrapper>
    );
}
 
export default Confirmation;