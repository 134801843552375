import React from 'react';

import flowersData from '../../../constants/pagesData/flowersData';
import { PageWrapper } from '../../styles';
import Card from '../../../components/Card';

const Flowers = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={flowersData} t={t}/>
        </PageWrapper>
    );
}
 
export default Flowers;