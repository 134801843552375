import flowers from '../../assets/img/hotelImages/reception/flowers.jpg';

const flowersData = {
    text : [
        "Para una ocasión muy especial, como un aniversario, un cumpleaños, una reunión familiar o simplemente desea dar una sorpresa a un ser querido, déjenos ayudarle.",
        "Realice su reserva en recepción."
    ],
    img : flowers
};

export default flowersData;