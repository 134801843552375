import HSPASubPages from "../../pages/InfoSubPages/HSPASubPages";
import Meetings from "../../pages/InfoSubPages/Meetings";
import Performances from "../../pages/InfoSubPages/Performances";
import ReceptionSubPages from "../../pages/InfoSubPages/ReceptionSubPages";
import Restaurants from "../../pages/InfoSubPages/Restaurants";
import Welcome from "../../pages/InfoSubPages/Welcome";

// images
import welcome from '../../assets/img/hotelImages/menuButtons/info/welcome.jpg';
import reception from '../../assets/img/hotelImages/menuButtons/info/reception.jpg';
import restaurant from '../../assets/img/hotelImages/menuButtons/info/restaurant.jpg';
import performances from '../../assets/img/hotelImages/menuButtons/info/performances.jpg';
import meetings from '../../assets/img/hotelImages/menuButtons/info/meetings.jpg';
import hspa from '../../assets/img/hotelImages/menuButtons/info/hspa.jpg';

const infoData = [
    {
        title : "Bienvenidos",
        url : "/info/welcome",
        page : Welcome,
        img : welcome,
    },
    {
        title : "Recepción",
        url : "/info/reception",
        page : ReceptionSubPages,
        subpage : true,
        img : reception,
    },
    {
        title : "Restaurantes",
        url : "/info/restaurants",
        page : Restaurants,
        img : restaurant,
    },
    {
        title : "HSPA",
        url : "/info/hspa",
        page : HSPASubPages,
        subpage : true,
        img : hspa
    },
    {
        title : "Reuniones y Eventos",
        url : "/info/meetings",
        page : Meetings,
        img : meetings
    },
    // {
    //     title : "Actuaciones",
    //     url : "/info/performances",
    //     page : Performances,
    //     img : performances
    // }
]

export default infoData;