import React from 'react';
import WelcomeWidget from '../components/WelcomeWidget';
import FeaturedWidget from '../components/FeaturedWidget';
import WeatherWidget from '../components/WeatherWidget';
import LanguageSelector from '../components/LanguageSelector';
import galleryImage from '../assets/img/bg_d.jpg';

const Home = ({ data, t }) => {
  const { imagenEstablecimiento, bg, nombre, poblacion } = data;

  return (
    <div>
      <WelcomeWidget logo={imagenEstablecimiento} bg={galleryImage} moto={nombre} />
      <FeaturedWidget data={data} t={t} />
      <WeatherWidget city={poblacion} t={t} />
      <LanguageSelector />
    </div>
  );
};

export default Home;
