import React from 'react';
import styled from 'styled-components';

// import { useDeviceInfo } from '../hooks/device';
import { API_URL } from '../constants/api';
import EstablishmentISOLogo from '../assets/img/logo_iso_hotel.svg';

// const { isMobile } = useDeviceInfo();

const WelcomeWidget = ({ logo, bg, moto }) => {
  const src = logo ? `data:image/jpeg;base64,${logo}` : EstablishmentISOLogo;

  return (
    <WidgetWrapper bg={bg}>
      <WidgetInner>
        <Logo src={src} alt='Logo Hotel' />
        <SubText>{moto}</SubText>
      </WidgetInner>
    </WidgetWrapper>
  );
};

export default WelcomeWidget;

/* width: ${isMobile ? '100%;' : '50%;'}*/
const Logo = styled.img`
  max-height: 60%;
  width: 100%;
  border-radius: 100%;
  margin-top: -1%;
`;

const SubText = styled.p`
  font-size: medium;
  font-weight: 600;
  color: #fff;
`;

const WidgetWrapper = styled.div`
  width: 100vw;
  height: 30vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${({ bg }) => bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const WidgetInner = styled.div`
  text-align: center;
  padding: 20px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;
