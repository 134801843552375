import React from 'react';
import { getLanguage } from '../../components/LanguageSelector';
import PDFViewer from '../../components/PDFViewer';
import styled from 'styled-components';

import es from '../../assets/files/envpol/es.pdf';
import en from '../../assets/files/envpol/en.pdf';

import energy from '../../assets/files/envpol/energy/file.pdf';
import carbon_pictogram from '../../assets/files/envpol/carbon/pictogram.jpg';
import carbon from '../../assets/files/envpol/carbon/certification.pdf';

import de from '../../assets/files/envpol/en.pdf'; /*  CHANGE */
import { PageWrapper } from '../styles';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const Header = ({ children: text }) => {
  return <h6 style={{ display: 'inline', width: '100%', textAlign: 'center' }}>{text}</h6>;
};

const EnvPol = ({ t }) => {
  const lng = getLanguage() || 'en';

  const files = {
    es: es,
    en: en,
    de: de,
  };

  return (
    <PageWrapper>
      <Collapse bordered={false} style={{ backgroundColor: 'transparent' }}>
        <MyPanel header={<Header>{t('Política medioambiental')}</Header>}>
          <PDFViewer url={files[lng]} />
        </MyPanel>
        <MyPanel header={<Header>{t('Eficiencia energética')}</Header>}>
          <PDFViewer url={energy} />
        </MyPanel>
        <MyPanel header={<Header>{t('Huella de carbono')}</Header>}>
          <PDFViewer url={carbon} />
          <img
            src={carbon_pictogram}
            style={{
              width: '100%',
              height: '10rem',
              objectFit: 'contain',
            }}
            alt='carbon policy pictogram'
          />
        </MyPanel>
      </Collapse>
    </PageWrapper>
  );
};

const MyPanel = styled(Panel)`
  position: relative;

  margin-bottom: 10px;
  background: white;
  border: 0px;
  border-radius: 5px !important;
  font-size: 1rem !important;

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
`;

export default EnvPol;
