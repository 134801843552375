import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Loading = () => {
    return (
        <LoaderWrapper><Spin size="large" /> </LoaderWrapper>
    )
}

export default Loading;

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
`;