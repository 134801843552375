import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from './languages/en.json';
import german from './languages/de.json';
import { getCookie } from "./utils";
import { selLangCookie } from "./components/LanguageSelector";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: english
    },
    de: {
        translation: german
    }
};

i18n.use(initReactI18next).init({
    resources, lng: getCookie(selLangCookie) || 'en',
    keySeparator: '>', nsSeparator: '|',
    interpolation: { escapeValue: false }
});

export default i18n;