import React from 'react';
import { Radio } from 'antd';
import Flags from 'country-flag-icons/react/3x2';
import i18next from 'i18next';
import styled from 'styled-components';
import { setCookie, getCookie } from '../utils';

const flagSize = '25px';
export const selLangCookie = 'vwlangsel';

const LanguageSelector = () => {
  const changeLanguage = (lang) => {
    setCookie(selLangCookie, lang, 2);
    i18next.changeLanguage(lang);
  };

  return (
    <LangSelector
      defaultValue={getCookie(selLangCookie) || 'en'}
      buttonStyle="solid"
      onChange={(e) => changeLanguage(e.target.value)}
    >
      <Radio.Button value="es">
        <FlagES />
      </Radio.Button>
      <Radio.Button value="en">
        <FlagEN />
      </Radio.Button>
      <Radio.Button value="de">
        <FlagDE />
      </Radio.Button>
    </LangSelector>
  );
};

export default LanguageSelector;

export const getLanguage = () => {
  return getCookie(selLangCookie);
};

const LangSelector = styled(Radio.Group)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  margin: 10px 17px;
`;
const FlagES = styled(Flags.ES)`
  width: ${flagSize};
`;
const FlagEN = styled(Flags.GB)`
  width: ${flagSize};
`;
const FlagDE = styled(Flags.DE)`
  width: ${flagSize};
`;
