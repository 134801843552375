import React from 'react';

import waterData from '../../../constants/pagesData/waterData';
import Card from '../../../components/Card';
import { PageWrapper } from '../../styles';

const WaterArea = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={waterData} t={t}/>
        </PageWrapper>
    );
}
 
export default WaterArea;