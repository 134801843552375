import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import MenuButton from '../MenuButton'

const Category = ({ title, img, onClick, t}) => {

    const data = {
        title,
        img
    }

    return (
        <MenuButton data={data} onClick={onClick} t={t}/>
    )
};

const CatNameWrapper = styled.div`
    display : flex;
    justify-content : center;
    align-items: center;
    text-align : center;
    color: white;
    padding : 5px;

    width : 100%;
    height : 100%;

    border-radius : 5px;
    background-color: rgba(0,0,0,0.5);
`;

const CatWrapper = styled.div`
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px solid #0000000f;
    border-radius: 5px;
    border-bottom: 1px solid #2222222b !important;
    /* box-shadow: 1px 1px 4px 1px #00000012; */

    background : white;
    background-image : url(${props => props.img});
    background-size : cover;
`;

export default Category;