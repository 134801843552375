import React from 'react';

import gymData from '../../../constants/pagesData/gymData';
import Card from '../../../components/Card';
import { PageWrapper } from '../../styles';

const Gym = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={gymData} t={t}/>
        </PageWrapper> 
    );
}
 
export default Gym;