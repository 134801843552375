import Axios from 'axios';
import { API_URL } from '../constants/api';
import { SET_MENU_DATA } from '../constants/index';
//SUPONINEDO QUE EL MENU QUE SE MUESTRA EN DIRECTORY ES SIEMPRE EL DE ROOM SERVICE

export const fetchMenuData = (estabID) => {
    return async dispatch => {
        try {
            const responseTPV = await Axios.get(`${API_URL}/tpv/getall/${estabID}`)
                .then(async (responseTPV) => {
                    const dayTpv = responseTPV.data.find( x => x.nombre === 'ROOM SERVICE').oid;
                    const midnightTPV = responseTPV.data.find( x => x.nombre === 'ROOM SERVICE MIDNIGHT')?.oid;

                    // PROMISES
                    const dayPromise = Axios.get(`${API_URL}/carta/getall/${dayTpv}`)
                    .then( response => {
                        return response.data;
                    })
                    .catch( err => {
                        console.log("🛠️ ~ file: menuActions.js ~ line 18 ~ err", err);
                        console.log("🛠️ ~ returning empty array to avoid more errors");
                        return [];
                    });
                    
                    const midnightPromise = Axios.get(`${API_URL}/carta/getall/${midnightTPV}`)
                    .then( response => {
                        return response.data;
                    })
                    .catch( err => {
                        console.log("🛠️ ~ file: menuActions.js ~ line 18 ~ err", err);
                        console.log("🛠️ ~ returning empty array to avoid more errors");
                        return [];
                    });

                    const [dayMenu, midnightMenu] = await Promise.all([dayPromise, midnightPromise]);

                    const menuData = {
                        day : dayMenu,
                        midnight : midnightMenu,
                    }

                    dispatch({type : SET_MENU_DATA, payload : menuData});

                    return menuData;
                }).catch(err => {
                    console.log("🛠️ ~ file: menuActions.js ~ line 49 ~ err", err);
                    return err?.status;
                });
            return responseTPV;
        } catch (error) {
            console.log("fetchMenuData -> error", error)
        }
    }
}
