const bottomBarData = [
    {
        title : "Explorar",
        url : "/explore"
    },
    {
        title : "Contacto",
        url : "/contact"
    }
]

export default bottomBarData;