import React from 'react';
import styled from 'styled-components';
import { WhiteBoard } from '../../components/styles';
import welcomeData from '../../constants/pagesData/welcomeData';

import { PageWrapper } from '../styles';
import Card from '../../components/Card';

const Welcome = ({t}) => {
    return (
        <WelcomeWrapper>
            <CardOverWriteWrapper>
            <Card data={{text : welcomeData}} t={t}/>
            </CardOverWriteWrapper>
        </WelcomeWrapper>
    );
}


const CardOverWriteWrapper = styled.div`
    text-align : center !important;
    p { 
        margin-bottom : 0.5rem  !important;
    }
`;

const WelcomeWrapper = styled(PageWrapper)`
`;
 
export default Welcome;