import reception from '../../assets/img/hotelImages/menuButtons/hspa/reception.jpg';

const receptionHSPAData = {
  text: [
    'Déjese mimar durante su estancia por nuestro equipo que le ofrecerá sofisticados tratamientos para sentirse relajado y sano y le facilitará cualquier tipo de información adicional que necesite.',
    'Horario : 10:30 a 20:00',
    'Puede contactar con nosotros marcando 735 desde el teléfono de su habitación',
  ],
  img: reception,
};

export default receptionHSPAData;
