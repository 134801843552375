import React from 'react';
import { Layout } from 'antd';
import { Grid } from 'react-flexbox-grid';
import AppMenu from './components/AppMenu';
import AppHeader from './components/AppHeader';

const { Content } = Layout;

const MainLayout = props => {

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout>
                <AppHeader t={props.t} />
                <Content style={{ minHeight: 280, paddingTop: 64, paddingBottom: 67 }} >
                    <Grid fluid className="p-0">
                        {props.children}
                    </Grid>
                </Content>
                <AppMenu t={props.t} />
            </Layout>
        </Layout>
    );
};

export default MainLayout;