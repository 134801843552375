import React, { useState, useEffect } from 'react';
import Category from './Category';
import { getCats } from './utils';
import styled from 'styled-components';
import { MenuWrapper } from '../../pages/styles';
import { useLocation } from 'react-router';
import Loading from '../../layout/Loading';

const CategoriesList = ({menu, t, onSelectCat}) => {

    const [ cats, setCats ] = useState();
    const { pathname } = useLocation();

    useEffect(() => {
        if (menu && !cats) {
            const menuCats = getCats(menu);
            setCats(menuCats);
        }
    }, [menu, cats]);
    

    return (
        <MenuCategoriesWrapper>
            {!menu && <Loading/>}
            {cats?.map((cat, i) => 
                <Category title={cat.name} img={cat.img} id={cat.id} key={`cat-${i}`} path={`${pathname}/${cat.id}`} onClick={() => onSelectCat(cat.id)} t={t}/>
            )}
        </MenuCategoriesWrapper>
    )
};

const MenuCategoriesWrapper = styled(MenuWrapper)``;

export default CategoriesList;

