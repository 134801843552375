import { keyBy } from 'lodash';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

// data source
import envPolData from '../constants/pagesData/envPolData';
import homeData from '../constants/pagesData/homeData';
import HSPAData from '../constants/pagesData/HSPAData';
import infoData from '../constants/pagesData/infoData';
import receptionData from '../constants/pagesData/receptionData';
import roomServiceData from '../constants/pagesData/roomServiceData';
import bottomBarData from '../constants/pagesData/bottomBarData';

const usePageTitle = () => {

    const dataSource = keyBy([].concat.apply([],[envPolData, homeData, HSPAData, infoData, receptionData, roomServiceData, bottomBarData]),'url');

    const { t } = useTranslation();
    const {pathname} = useLocation();
    const title = useRef();
    
    const getTitle = () => {

        const pathTitle = dataSource[pathname]?.title;

        if(pathTitle){
            title.current = dataSource[pathname].title;
            return dataSource[pathname].title;
        }
        if(!pathTitle && title.current){
            return title.current;
        }
        if(!pathTitle && !title){
            return "GRPO Valparaiso Palace";
        }
    }

    return t(getTitle()).toUpperCase();
}
 
export default usePageTitle;