import paraiso from '../../assets/img/hotelImages/menuButtons/restaurants/paraiso.jpg';
import bistro from '../../assets/img/hotelImages/menuButtons/restaurants/bistro.jpg';
import dao from '../../assets/img/hotelImages/menuButtons/restaurants/dao.jpg';

const restaurantsData = [
  {
    title: 'Restaurante Paraíso',
    subtitle: 'Desayuno - Un exquisito buffet',
    extraInfo: ['Teléfono : 6289'],
    text: [
      'Para empezar el día con la mejor energia posible no hay nada como el selecto desayuno del Restaurante Paraíso.',
      'En nuestro espectacular buffet con showcooking econtrará desde fruta fresca, zumos y bollería hasta un rincón mallorquín y un rincón saludable.',
      'Nuestro equipo estará siempre disponible para atenderle y convertir su desayuno en una experiencia gastronómica inolvidable.',
    ],
    img: paraiso,
  },
  {
    title: 'Bistro Mar Blau',
    subtitle: 'La auténtica atmósfera',
    extraInfo: ['Teléfono : 828', 'Room Service : 6289'],
    text: [
      'En el cálido Restaurante a la carta “Mar Blau” podrá tomar desde un delicioso plato de pasta hasta una ligera y fresca ensalada, siempre cocinados con toques únicos y con productos de la mejor calidad.',
      'Disfrute de un almuerzo perfecto o de una cena exquisita tanto en el interior del restaurante, decorado con un claro estilo mediterráneo, como en su espectacular terraza con espectaculares vistas.',
    ],
    img: bistro,
  },
  {
    title: 'Pool Restaurante DAO',
    subtitle: 'El sabor de las vacaciones',
    extraInfo: ['Teléfono : 819'],
    text: [
      'En el Pool Bar encontrará un ambiente informal al lado de nuestra piscina donde disfrutar de una comida ligera y saludable basada en la experiencia de la cocina mediterránea.',
      'Si se aloja en el GPRO Valparaiso Palace & SPA Hotel durante el verano podrá tomar almuerzos frescos cada mediodía en el DAO Pool Bar.',
    ],
    img: dao,
  },
];

export default restaurantsData;
