import React from 'react';

import excursionsData from '../../../constants/pagesData/excursionsData';
import { PageWrapper } from '../../styles';
import Card from '../../../components/Card';

const Excursions = ({t}) => {
    return (  
        <PageWrapper>
            <Card data={{subtitle : excursionsData.title}} t={t}/>
            {excursionsData.content.map((data,index) => 
                <Card key={index} data={data} t={t}/>
            )}
        </PageWrapper>
    );
}
 
export default Excursions;