import React, { useRef, useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { MAP_TOKEN } from '../constants/api';

mapboxgl.accessToken = MAP_TOKEN;


const defaultPlace = {
    lat: 0.0,
    lng: 0.0,
}

const MapWidget = () => {

    const mapRef = useRef();
    const map = useSelector(state => state.map);
    const [state] = useState({
        lat: map?.features[0].center[1] || defaultPlace.lat,
        lng: map?.features[0].center[0] || defaultPlace.lng,
        zoom: 15
    })

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [state.lng, state.lat],
            zoom: state.zoom
        });
        new mapboxgl.Marker()
            .setLngLat([state.lng, state.lat])
            .addTo(map);

    }, [state.lat, state.lng, state.zoom])

    return (<MapWrapper ref={mapRef} />)
}

export default MapWidget;

const MapWrapper = styled.div`
    position: relative;
    height: 40vh;

    .mapboxgl-ctrl-bottom-right{
        display : none;
    }
`;