import React, { useEffect, Fragment, useState, useCallback } from 'react';
import MainContainer from './containers/MainContainer';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEstablishmentData } from './actions/establishmentActions';
import { getID } from './utils';
import { Result } from 'antd';
import Loading from './layout/Loading';


const App = () => {

  const ID = getID();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const establishment = useSelector(state => state.establishment);
  const [loading, setLoading] = useState(false);

  const getInitialData = useCallback(async () => {
    setLoading(true);
    const response = await dispatch(fetchEstablishmentData(ID));
    if (response === 200) {
      setLoading(false);
    } else {
      setLoading(false)
    }
  }, [ID, dispatch])

  useEffect(() => {
    if (!establishment) {
      getInitialData()
    }
  }, [getInitialData, establishment]);

  if (loading) { return <Loading /> }
  return (
    <Fragment>
      {
        establishment
          ? <MainContainer data={establishment} t={t} />
          : <Result status="404" title={t('NO SE HA ENCONTRADO EL ESTABLECIMIENTO')} />
      }
    </Fragment>
  );
}

export default App;
