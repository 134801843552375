import React from 'react';
import { useHistory } from 'react-router';

const ScrollToTop = () => {

    const history = useHistory();

    history.listen(() => {
        window.scrollTo(0,0);
    })

    return null;
}

export default ScrollToTop;