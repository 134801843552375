import Day from '../../pages/RoomSerSubPages/Day';
import Midnight from '../../pages/RoomSerSubPages/Midnight';

const roomServiceData = [
    {
        title : "Room service",
        page : Day,
        tabNum : 0,
        url : "/roomservice/0/:category?"
    },
    {
        title : "Room service medianoche",
        page :  Midnight,
        tabNum : 1,
        url : "/roomservice/1"
    }
];

export default roomServiceData;