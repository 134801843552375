import React from 'react';

import receptionHSPAData from '../../../constants/pagesData/receptionHSPAData';
import Card from '../../../components/Card';
import { PageWrapper } from '../../styles';

const ReceptionHSPA = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={receptionHSPAData} t={t}/>
        </PageWrapper>
    );
}
 
export default ReceptionHSPA;