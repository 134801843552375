import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import envPolData from '../../constants/pagesData/envPolData';
import { RouteTransition } from '../../styles/AnimatedRoutes';
import EnvPolMenu from '../EnvPolMenu';

const EnvPolRoutes = ({t}) => {
    let { path } = useRouteMatch();

    return (  
        <Switch>
            <RouteTransition exact path={path}>
                <EnvPolMenu t={t}/>
            </RouteTransition>
            {envPolData.map((data,index) =>
                <RouteTransition path={data.url} key={"ENVPOL_"+index}>
                    <data.page t={t}/>
                </RouteTransition>
            )}
        </Switch>
    );
}
 
export default EnvPolRoutes;