import React from 'react';

import saunaData from '../../../constants/pagesData/saunaData';
import Card from '../../../components/Card';
import { PageWrapper } from '../../styles';

const SaunaArea = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={saunaData} t={t}/>
        </PageWrapper>
    );
}
 
export default SaunaArea;