import React, { useState } from 'react';
import { API_URL } from '../constants/api';
import { Document, Page, pdfjs } from 'react-pdf';
import { AddWrapper, ButtonsWrapper, DocumentWrapper, RestWrapper } from './styles';
import { ADD_ICON, REST_ICON } from '../constants/icons';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js';

const PDFViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(0);
  const [scale, setScale] = useState(0.6);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderPages = () => {
    const pages = [];

    for (let index = 0; index < numPages; index++) {
      pages.push(<Page key={index} pageNumber={index + 1} renderTextLayer={false} scale={scale} />);
    }

    return pages;
  };

  const zoomIn = () => {
    setScale((prev) => {
      if (prev + 0.2 > 4.0) {
        return prev;
      }
      return prev + 0.2;
    });
  };
  const zoomOut = () => {
    setScale((prev) => {
      if (prev - 0.2 < 0.4) {
        return prev;
      }
      return prev - 0.2;
    });
  };

  const renderButtons = () => {
    return (
      <ButtonsWrapper>
        <AddWrapper onClick={zoomIn}>{ADD_ICON}</AddWrapper>
        <RestWrapper onClick={zoomOut}>{REST_ICON}</RestWrapper>
      </ButtonsWrapper>
    );
  };

  const onDocumentLoadError = (error) => {
    console.log('PDF ERROR', error);
  };

  return (
    <>
      <DocumentWrapper>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
          {renderPages()}
        </Document>
      </DocumentWrapper>
      {renderButtons()}
    </>
  );
};

export default PDFViewer;
