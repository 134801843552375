import React from 'react';
import homeData from '../constants/pagesData/homeData';
import { HomeMenuWrapper } from '../pages/styles';
import HomeMenuButton from './HomeMenuButton';

const FeaturedWidget = ({t}) => {

    return (
        <HomeMenuWrapper>
            {homeData.map(data => 
                <HomeMenuButton key={data.title} data={data} t={t}/>
            )}
        </HomeMenuWrapper>)
};

export default FeaturedWidget;

// const WidgetWrapper = styled.div`
//     min-height: 20vh;
//     padding: 12px;
//     padding-bottom: 5px;
//     margin-top: -50px;
//     background-color: #f1f2f5;
//     border-radius: 30px;
//     z-index: 12;
//     display: flex;
//     flex-flow: row;
//     flex-wrap: wrap;
//     align-items: center;
//     justify-content: space-between;
// `;