import React from 'react';

import golfData from '../../../constants/pagesData/golfData';
import { PageWrapper } from '../../styles';
import Card from '../../../components/Card';

const Golf = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={golfData} t={t}/>
        </PageWrapper>
    );
}
 
export default Golf;