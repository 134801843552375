const weDoData = [
    {
        title : "Ahorramos energía",
        text : [
            "Aprovechamos la luz natural",
            "Utilizamos interruptores con desconexión automática",
            "Utilizamos únicamente bombillas de LED",
            "Instalamos sistemas de Domótica con sensores de presencia para ahorrar energía en las habitaciones",
            "Tenemos estaciones de recarga de coches eléctricos"
        ]
    },
    {
        title : "Ahorramos agua",
        text : [
            "Instalamos reductores de caudal en grifos y ducha",
            "Favorecemos el cambio de toallas según criterio ecológico del cliente",
            "Regamos a las horas de menor insolación",
            "En nuestros jardines predominan las plantas autóctonas"
        ]
    },
    {
        title : "Compramos pensando en verde",
        text : [
            "Aplicamos criterios ambientales a la hora de comprar",
            "Damos prioridad a productos autóctonos para reducir transportes",
            "Reducimos el consumo de productos tóxicos",
            "Utilizamos papel reciclado o blanqueado sin cloro"
        ]
    },
    {
        title : "Gestionamos la contaminación y los residuos",
        text : [
            "Clasificamos la basura para reciclar",
            "Controlamos nuestras emisiones atmosféricas",
            "Vigilamos el ruido que producimos",
            "Entregamos residuos peligrosos a gestores autorizados"
        ]
    },
    {
        title : "Adquirimos formación ambiental",
        text : [
            "Tenemos un plan de formación ambiental para nuestro personal",
            "Estamos preparados para responder a emergencias ambientales",
            "Disponemos de responsable medioambiental en nuestro hotel"
        ]
    },
    // { FALTA LA DIAPOSITIVA
    //     title : "Tenemos un compromiso con el medio ambiente"
    // }
]

export default weDoData;