import React from 'react';

import transferData from '../../../constants/pagesData/transferData';
import { PageWrapper } from '../../styles';
import Card from '../../../components/Card';

const Transfer = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={transferData} t={t}/>
        </PageWrapper>
    );
}
 
export default Transfer;