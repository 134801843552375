import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';
import ProductList from '../../components/Menu/ProductList';
import { PageWrapper } from '../styles';

const Midnight = ({t}) => {

    const products = useSelector(state => state.menu.midnight);
    console.log("🛠️ ~ file: Midnight.js ~ line 10 ~ Midnight ~ products", products);

    return (  
        <PageWrapper>
        <FadeIn>
            <ProductList product_list={products} t={t} hidePhoto/>
        </FadeIn>
        </PageWrapper>
    );
}
 
export default Midnight;