import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 12px;
  position: relative;

  & > div {
    margin-bottom: 1rem;
  }

  height: 100%;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    min-height: 120px;
  }
`;

export const HomeMenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(120px, auto);
  grid-auto-columns: minmax(163px, auto);
  gap: 16px;
  justify-items: center;
  padding: 12px;
`;
