import React from 'react';

import consiergeData from '../../../constants/pagesData/consiergeData';
import { PageWrapper } from '../../styles';
import Card from '../../../components/Card';

const Consierge = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={consiergeData} t={t}/>
        </PageWrapper>
    );
}
 
export default Consierge;