import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import { isEmpty } from 'lodash';
import 'react-awesome-slider/dist/styles.css';
import styled from 'styled-components';

const ImageSlider = ({ images, contain = false }) => {
    if (isEmpty(images)) { return null }
    return (
        <Slider {...sliderProps} contain={contain}>
            {
                images.map(
                    (i, index) => <div data-src={i} style={{objectFit : 'contain'}}key={`slide-${index}`} />)
            }
        </Slider>
    )
}

export default ImageSlider;

const Slider = styled(AwesomeSlider)`
    width: 90%;
    margin: auto;
    & > .awssld__wrapper {
        border-radius: 10px
    }

    & img {
        ${props => props.contain && 'object-fit : contain;'}
    }
`;

const sliderProps = {
    bullets: false,
    infinite: true,
    startup: true,
    mobileTouch: true,
    style: { width: '90%', margin: 'auto' }
}