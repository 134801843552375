import welcome from '../../assets/img/hotelImages/menuButtons/hspa/welcome.jpg';

const welcomeHSPAData = {
    text : [
        "Cierre los ojos y deje que le envuelva un cálido ambiente de silencio, paz y calma. Nada más acceder a la zona de aguas empezará a vivir una experiencia reconfortante y saludable.",
        "Tras su ruta por nuestro SPA, uno de los más grandes y completos en España, se sentirá como nuevo por dentro y por fuera gracias a las modernas y variadas instalaciones.",
        "Puede bajar confortablemente desde su habitación con el ascensor central pulsando el botón W&S.",
        "En su habitación tiene albornoz y zapatillas, toallas puede cojer directamente en la zona del spa."
    ],
    img : welcome
}

export default welcomeHSPAData;