import bike from '../../assets/img/hotelImages/menuButtons/reception/bookings.jpg';
import car from '../../assets/img/hotelImages/bookings/car.jpg';
import helicopter from '../../assets/img/hotelImages/bookings/helicopter.jpg';

const bookingsData = {
    text : ["Servicio de alquileres de coches, motos, bicicletas, yates, helicopteros,..."],
    img : [
        bike,
        car,
        helicopter,
    ]
};

export default bookingsData;