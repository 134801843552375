import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import styled from 'styled-components';
import { Spin, Empty } from 'antd';
moment().locale('es');

const WEATHER_API_URL = "https://api.openweathermap.org/data/2.5/";
const API_KEY = "6d95f2c24437dca902c7ecff8b0654c7";
const ICON_URL = "https://openweathermap.org/img/wn/";

const WeatherWidget = ({ city, t }) => {

    const [wData, setWData] = useState();

    const getWeather = async (city, type) => {
        if (city) {
            Axios.get(`${WEATHER_API_URL}${type}?q=${city}&appid=${API_KEY}`)
                .then(response => {
                    if (type === 'weather') {
                        const { temp_min, temp_max, humidity } = response.data.main;
                        const { icon } = response.data.weather[0]
                        setWData(
                            {
                                temp_min: ktoc(temp_min),
                                temp_max: ktoc(temp_max),
                                humidity,
                                icon
                            }
                        )
                    }
                }).catch(err => console.log(err))
        }
    }

    useEffect(() => {
        getWeather(city, 'weather');
    }, [city])

    if (!city) { return <Empty description={t('No se encuentra la ciudad.')} /> }

    if (!wData) { return <div className="text-center my-4"> <Spin size="large" /> </div> }

    return (
        <InfoWrapper>
            <WeatheIcon src={`${ICON_URL}${wData.icon}@4x.png`} alt="weather-icon" />
            <div>
                <TempInfo>{`${wData?.temp_min}ºC - ${wData?.temp_max}ºC`}</TempInfo>
                <HumInfo>{`${t('Humedad:')} ${wData?.humidity}%`}</HumInfo>
                <DateInfo>{moment().format('L')}</DateInfo>
            </div>
        </InfoWrapper>
    )
};

export default WeatherWidget;

const ktoc = kelvin => Math.round(kelvin - 273.15);

const WeatheIcon = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const InfoWrapper = styled.div`
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    margin-left: 17px;
    margin-right: 17px;
    background-color: #fff;
    border-radius: 5px;
    border-bottom: 1px solid #2222222b !important;
`;

const TempInfo = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
`;

const DateInfo = styled.div`
    font-size: 9px
`;

const HumInfo = styled.div`
    font-size: 13px;
`;