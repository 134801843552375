import React from 'react';
import MapWidget from '../components/MapWidget';
import ContactCardWidget from '../components/ContactCardWidget';

const Contact = ({ data, t}) => {
    const contact = getContactData(data);
    return (
        <div>
            <MapWidget/>
            <ContactCardWidget contact={contact} t={t}/>
        </div>
    )
}

const getContactData = (data) => {
    const contact = {
        adress : data?.direccion,
        phone : data?.telefono,
        email : data?.correo
    }
    return contact;
}

export default Contact;
