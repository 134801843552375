import boutique from '../../assets/img/hotelImages/menuButtons/hspa/boutique.jpg';

const boutiqueData = {
    subtitle : "Productos de belleza",
    text : [
        "Si está buscando los mejores productos de belleza cons los que podrá continuar sus sesiones de relax en casa, sólo tiene que acercarse a la boutique que encontrará en la entrada de nuestro SPA."
    ],
    img : boutique
}

export default boutiqueData;