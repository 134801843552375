import meetings from '../../assets/img/hotelImages/menuButtons/info/meetings.jpg';

const meetingsData = {
    text : [
        "Una reunión de negocios, una conferencia o un congreso... todos requieren mucho más que una buena sala en la que celebrarse.",
        "Además de contar con 10 salas completamente equipadas y preparadas tanto para grandes reuniones como para pequeños eventos, en el GPRO Valparaiso Palace & SPA encontrará los mejores servicios e instalaciones en un entomo extraordinario."
    ],
    img : meetings
}

export default meetingsData;