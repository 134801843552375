import React from 'react';
import { getLanguage } from '../components/LanguageSelector';
import PDFViewer from '../components/PDFViewer';

import es from '../assets/files/covid/es.pdf';
import en from '../assets/files/covid/en.pdf';
import de from '../assets/files/covid/de.pdf';
import { PageWrapper } from './styles';

const files = {
    es : es,
    en : en,
    de : de
}

const Covid = () => {

    const lng = getLanguage() || 'en';

    return (
        <PageWrapper>
        <PDFViewer url={files[lng]}/>
        </PageWrapper>
    );
}
 
export default Covid;