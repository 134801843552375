import React from 'react';
import { useHistory } from 'react-router';
import { FeaturedIcon, WidgetIcon,WidgetText } from './SingleWidgets/styles';

const HomeMenuButton = ({data, t}) => {

    const history = useHistory();

    const handleClick = () => {
        if(data.url){
            history.push(data.url);
        }
    }

    return (
        <FeaturedIcon onClick={handleClick}>
            {data.icon && <WidgetIcon src={data.icon} alt={data?.title}/>}
            <WidgetText >{t(data.title).toUpperCase()}</WidgetText>
        </FeaturedIcon>
    );
}
 
export default HomeMenuButton;