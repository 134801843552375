const welcomeData = [
    "Estimado huésped,",
    "Es un honor para nosotros ser sus anfitriones.",
    "Le doy la más cordial bienvenida en nombre del personal y en particular del mío propio.",
    "Les aseguramos que hemos y haremos todo lo posible para que su estancia sea completamente placentera y logremos satisfacer sus expectativas.",
    "Si tuviera cualquier duda o consulta siéntase libre de llamarnos; estamos a su entera disposición.",
    "Nuestro compromiso es ofrecerle el mejor trato para que su estancia sea una experiencia inolvidable que cautive todos sus sentidos y le haga sentir como en su casa.",
    "Agradecemos su lealtad y le reiteramos que estamos a su entera disposición.",
    "Toni Ferrer",
    "GRPO Valparaíso Palace",
    "Director General"
]

export default welcomeData;