import React from 'react';
import MenuButton from "../components/MenuButton";
import envPolData from '../constants/pagesData/envPolData';
import { MenuWrapper } from './styles';

const EnvPolMenu = ({t}) => {
    return (
        <MenuWrapper>
            {envPolData.map((data,index) => 
                <MenuButton data={data} t={t} key={"ENV_POL_MENU"+index}/>    
            )}
        </MenuWrapper>
    );
}
 
export default EnvPolMenu;