import React from 'react';
import styled from 'styled-components';
import HSPAData from '../../constants/pagesData/HSPAData';
import MenuButton from '../../components/MenuButton';
import { MenuWrapper } from '../styles';

const HSPA = ({t}) => {
    return ( 
        <HSPAWrapper>
            {HSPAData.map(data => 
                <MenuButton data={data} t={t} key={data.url}/>
            )}
        </HSPAWrapper> 
    );
}

const HSPAWrapper = styled(MenuWrapper)``;
 
export default HSPA;