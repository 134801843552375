import React from 'react';

import bookingsData from '../../../constants/pagesData/bookingsData';
import { PageWrapper } from '../../styles';
import Card from '../../../components/Card';

const Bookings = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={bookingsData} t={t}/>
        </PageWrapper>
    );
}
 
export default Bookings;