const transferData = {
    subtitle : "Reserve su Transfer privado",
    textIsList : true,
    text : [
        "Traslado Privado",
        "Servicio Aeropuerto",
        "Limousine Service",
        "Minibus Deluxe Service hasta 9 plazas",
        "Autobuses de lujo"
    ]
};

export default transferData;