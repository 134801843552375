import styled from 'styled-components';

export const WidgetIcon = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 26%;
`;

export const WidgetText = styled.h6`
    font-weight: 600;
    font-size: 1rem;
    margin: 5px;
    padding : 5px;
`;

export const FeaturedIcon = styled.div`
background-color: #FFF;
display: flex;
justify-content: center;
align-items: center;
flex-flow: column;
text-align: center;
cursor: pointer;
width: 100%;
height: 100%;
border-radius: 10px;
box-shadow : 0px 2px 0px rgba(0, 0, 0, 0.05);
`;