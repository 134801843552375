import { isArray } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { WhiteBoard } from './styles';

// data : {
//     title : String,
//     text : [String],
//     img : file/url
// }
const Card = ({ data, t }) => {
  const renderText = () => {
    if (data.textIsList) {
      return (
        <ul>
          {data.text.map((text, index) => (
            <li key={index}>{t(text)}</li>
          ))}
        </ul>
      );
    }
    if (!data.textIsList) {
      return data.text.map((text, index) => <p key={index}>{t(text)}</p>);
    }
  };

  const renderImages = () => {
    const images = (() => {
      if (!isArray(data.img)) {
        return [data.img];
      }
      return data.img;
    })();

    return images.map((img, index) => <img key={index} src={img} alt={data.title} />);
  };

  return (
    <CardWrapper>
      {data.title && <h5>{t(data.title)}</h5>}
      {data.subtitle && <Subtitle>{t(data.subtitle)}</Subtitle>}
      <div className='extraInfo'>{data.extraInfo && data.extraInfo.map((eInfo, index) => <ExtraInfo key={index}>{t(eInfo)}</ExtraInfo>)}</div>
      {data.text && renderText()}
      {data.img && renderImages()}
    </CardWrapper>
  );
};

export default Card;

const Subtitle = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const ExtraInfo = styled.span`
  font-style: italic;
`;

const CardWrapper = styled(WhiteBoard)`
  h5 {
    text-transform: uppercase;
    font-weight: 700;
  }

  img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .extraInfo {
    display: flex;
    gap: 10px;
  }
`;
