import treatment from '../../assets/img/hotelImages/menuButtons/hspa/treatment.jpg';

const treatmentData = {
    text : [
        "Cada cierto tiempo resulta esencial encontrar un momento de tranquilidad y dejar que los mejores profesionales nos ayuden a mimarnos.",
        "En las 11 cabinas de tratamiento individual y en la cabina VIP para tratamientos en pareja que ponemos a su disposición en el Hotel GPRO Valparaiso Palace & SPA, podrá elegir entre una extensa selección de los mejores tratamintos para relajarse y cuidarse."
    ],
    img : treatment
}

export default treatmentData;