import React from 'react';
import { useSelector } from 'react-redux';
import CategoriesList from '../../components/Menu/CategoriesList';
import { Route, Switch, useHistory } from 'react-router';
import CategoryProducts from '../CategoryProducts';

const Day = ({t}) => {

    const menu = useSelector(state => state.menu?.day);
    const history = useHistory();

    const handleSelectCat = (catID) => {
        history.push(`/roomservice/0/${catID}`);
    }

    return (
        <Switch>
            <Route exact path="/roomservice/0">
                <CategoriesList menu={menu} t={t} onSelectCat={handleSelectCat}/>
            </Route>
            <Route exact path="/roomservice/0/:category">
                <CategoryProducts menu={menu} t={t}/>
            </Route>
        </Switch>
    );
}
 
export default Day;