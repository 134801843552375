import React from 'react';
import styled from 'styled-components';
import MenuButton from '../../components/MenuButton';
import receptionData from '../../constants/pagesData/receptionData';
import { MenuWrapper } from '../styles';
const Reception = ({t}) => {
    return ( 
        <ReceptionWrapper>
            {receptionData.map((data,index)=> 
                <MenuButton key={"RECEPTION_"+index} data={data} t={t}/>
            )}
        </ReceptionWrapper>
    );
}

const ReceptionWrapper = styled(MenuWrapper)``;
 
export default Reception;