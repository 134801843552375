import React from 'react';

import boutiqueData from '../../../constants/pagesData/boutiqueData';
import Card from '../../../components/Card';
import { PageWrapper } from '../../styles';

const Boutique = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={boutiqueData} t={t}/>
        </PageWrapper>
    );
}
 
export default Boutique;