import React from 'react';
import { Switch, useRouteMatch } from 'react-router';

import HSPA from '../HSPA';
import WelcomeHSPA from './WelcomeHSPA';
import ReceptionHSPA from './ReceptionHSPA';
import Treatment from './Treatment';
import Boutique from './Boutique';
import SaunaArea from './SaunaArea';
import Hairdresser from './Hairdresser';
import Gym from './Gym';
import WaterArea from './WaterArea';
import FitCourses from './FitCourses';
import { RouteTransition } from '../../../styles/AnimatedRoutes';
import HSPAData from '../../../constants/pagesData/HSPAData';


const HSPASubPages = ({t}) => {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <RouteTransition exact path={path}>
                <HSPA t={t}/>
            </RouteTransition>
            {HSPAData.map((data,index) => 
                <RouteTransition exact path={data.url} key={"HSPA_"+index}>
                    <data.page t={t}/>
                </RouteTransition>    
            )}
        </Switch>
    );
}
 
export default HSPASubPages;