import React from 'react';
import styled from 'styled-components';
import MenuButton from '../components/MenuButton';

import infoData from '../constants/pagesData/infoData';
import { MenuWrapper } from './styles';

const Info = ({t}) => {

    return (
        <InfoWrapper>
            {infoData.map(data => 
                <MenuButton key={data.title} data={data} t={t}/>
            )}
        </InfoWrapper>
    );
}
 
export default Info;

const InfoWrapper = styled(MenuWrapper)``;
