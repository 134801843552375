import React from 'react';
import { Route, Switch } from 'react-router';
import Tabs from '../components/Tabs';
import roomServiceData from '../constants/pagesData/roomServiceData';
import styled from 'styled-components'

const RoomService = ({ t }) => {

    return (
        <RoomServiceWrapper>
        <Tabs tabs={roomServiceData} t={t}/>
        <Switch>
            {roomServiceData.map(data => 
                <Route path={data.url} key={data.tabNum}>
                    <data.page t={t}/>
                </Route>    
            )}
        </Switch>
        </RoomServiceWrapper>
    )
}

const RoomServiceWrapper = styled.div`
    position : relative;
    height : 100%;
`;

export default RoomService;