import water from '../../assets/img/hotelImages/menuButtons/hspa/water.jpg';

const waterData = {
    text : [
        "Todas nuestras piscinas y jacuzzis son de agua dulce tratadas con sal para minimizar el uso de productos químicos.",
        "En el HSPA la temperatura de las piscinas oscila entre 30 y 33 grados todo el año.",
        "Su uso a menores de 16 años solo está permitido de 10:00 a 13:00 y siempre bajo la vigilancia de un adulto."
    ],
    img : water
}

export default waterData;