import boheme from '../../assets/img/hotelImages/performances/boheme.jpg';

const performancesData = [
    {
        title : "La Boheme",
        text : ["Los viernes, quincenalmente a las 20.30 hrs. en nuestro Bistro Mar Blau"],
        img : boheme,
    },
];

export default performancesData;