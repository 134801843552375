import sauna from '../../assets/img/hotelImages/menuButtons/hspa/sauna.jpg';

const saunaData = {
    subtitle : "Ponemos a su disposición :",
    text : [
        "Sauna finlandesa",
        "Baño de inhalación salina",
        "Baño de hierbas",
        "Baño turco",
        "Fuente de hielo",
        "Duchas de sensaciones",
        "Pediluvio",
        "Zona de relajación con camas de agua térmica",
        "Sala de meditación profunda"
    ],
    textIsList : true,
    img : sauna,
    
}

export default saunaData;