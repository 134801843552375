import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import MountTransition from './MountTransition';
import history from '../history';

export const AnimatedRoutes = props => {
    const { children, exitBeforeEnter = true, initial = false } = props;
    const location = useLocation();

    return (
        <AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
            <Switch>
                {children}
            </Switch>
        </AnimatePresence>
    )
}

export const RouteTransition = (props) => {
    const { children, exact = false, path, slide = 0, slideUp = 0, ...rest } = props;
    return (
        <Route exact={exact} path={path} {...rest}>
            <MountTransition slide={slide} slideUp={slideUp}>
                {children}
            </MountTransition>
        </Route>
    )
}

