import { handleActions } from 'redux-actions';
import { SET_ESTABLISHMENT_DATA, SET_MENU_DATA, SET_MAP_DATA } from '../constants';

export const establishment = handleActions({
    [SET_ESTABLISHMENT_DATA]: (state, action) => action.payload,
}, null)

export const menu = handleActions({
    [SET_MENU_DATA]: (state, action) => action.payload,
}, null)

export const map = handleActions({
    [SET_MAP_DATA]: (state, action) => action.payload,
}, null)
