import Axios from 'axios';
import { MAP_API_URL,MAP_TOKEN } from '../constants/api';
import { SET_MAP_DATA } from '../constants/index';
//SUPONINEDO QUE EL MENU QUE SE MUESTRA EN DIRECTORY ES SIEMPRE EL DE ROOM SERVICE

export const fetchMapData = (establishment) => {
    return async dispatch => {
        try {
            const infoEncode = encodeURIComponent(`${establishment.nombre},${establishment?.poblacion} ${establishment?.cp}, ${establishment.pais}`);
            const response = await Axios.get(`${MAP_API_URL}/${infoEncode}.json?limit=1&access_token=${MAP_TOKEN}`)
            .then(response => {
                dispatch({
                    type : SET_MAP_DATA,
                    payload : response.data
                })
                return response.status;
            }).catch(err => {
                console.log(err)
                return response.status;
            });
            return response;
        } catch (error) {
            console.log("fetchMapData -> error", error)
        }
    }
}
