import hairdresser from '../../assets/img/hotelImages/menuButtons/hspa/hairdresser.jpg';

const hairdresserData = {
    subtitle : "Los mejores profesionales",
    text : [
        "Tanto si quiere un tratamiento para el cabello como si está buscando un cambio de imagen, un corte de pelo o un peinado elegante, en la peluquería del Hotel GPRO Valparaiso Palace & SPA encontrará los mejores profesionales listos para atenderle."
    ],
    img : hairdresser
}

export default hairdresserData;