import React from 'react';
import { PageWrapper } from '../styles';
import Card from '../../components/Card';
import restaurantsData from '../../constants/pagesData/restaurantsData';

const Restaurants = ({ t }) => {
  return (
    <PageWrapper>
      {restaurantsData.map((data, index) => (
        <Restaurant data={data} t={t} key={'RSTNR_' + index} />
      ))}
    </PageWrapper>
  );
};

const Restaurant = ({ data, t }) => {
  return <Card data={data} t={t} />;
};

export default Restaurants;
