import React from 'react';
import { Switch } from 'react-router';
import receptionData from '../../../constants/pagesData/receptionData';
import { RouteTransition } from '../../../styles/AnimatedRoutes';
import Reception from '../Reception';

const ReceptioSubPages = ({t}) => {

    return (
        <Switch>
            <RouteTransition exact path="/info/reception">
                <Reception t={t}/>
            </RouteTransition>
            {receptionData.map((data,index) => 
                <RouteTransition exact path={data.url} key={"RECEPTION_MENU"+index}>
                    <data.page t={t}/>
                </RouteTransition>
            )}
        </Switch>
    );
}
 
export default ReceptioSubPages;