import React from 'react';

import { useRouteMatch, Switch, Route } from 'react-router-dom';

import Info from '../Info';
// INFO PAGES

import { RouteTransition } from '../../styles/AnimatedRoutes';
import infoData from '../../constants/pagesData/infoData';

const InfoSubPages = ({t}) => {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <RouteTransition exact path={path}>
                <Info t={t}/>
            </RouteTransition>
            {/* INFO SUBPAGES */}
            {infoData.map((data,index) => 
                <RouteTransition path={data.url} exact={!data.subpage} key={"INFO_"+index}>
                    <data.page t={t}/>
                </RouteTransition>
            )}
        </Switch>
    );
}
 
export default InfoSubPages;