import React from 'react';
import usePageTitle from '../../hooks/usePageTitle';
import { Layout, Button } from 'antd';
import { useLocation} from 'react-router-dom';
import styled from 'styled-components';
import { GO_BACK_ICON } from '../../constants/icons';
import history from '../../history';

const { Header } = Layout;

const AppHeader = ({ t }) => {

    const location = useLocation();
    const headerTitle = usePageTitle();
    

    // const headerTitle = useSelector(state => state.headerTitle);
    
    const handleGoBack = () => {
        if (location !== '/') {
            history.goBack();
        }
    }

    return (
        <HeaderWrapper>
            {
                location.pathname === '/'
                    ? <h6>{t('BIENVENIDO')}</h6>

                    : <HeaderInnerWrapper>
                        <Button size="large" type="link" icon={GO_BACK_ICON} onClick={handleGoBack} style={{ color: '#FFF', float: 'left' }} />
                        <TitleWrapper>
                            <h6>{headerTitle}</h6>
                        </TitleWrapper>
                    </HeaderInnerWrapper>
            }
        </HeaderWrapper>
    )
};

export default AppHeader;

const TitleWrapper = styled.div`
    display : flex;
    width : 100%;
    justify-content : center;
    align-items: center;

    text-align : center;
    padding : 0 10%;
`;

const HeaderInnerWrapper = styled.div`
    width : 100%;
    display : grid;
    grid-template-columns : 0.1fr 1fr 0.1fr
`;

const HeaderWrapper = styled(Header)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    h6 {
        font-weight: 600;
        color: #FFF;
        margin-bottom: 0px !important;
    }
    z-index: 100;
    position: fixed;
    width: 100%;
`;