import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { FeaturedIcon, WidgetIcon,WidgetText } from './SingleWidgets/styles';

import noFileImage from '../assets/img/hotelImages/menuButtons/nofile.jpg';

const MenuButton = ({data, t, onClick}) => {

    const history = useHistory();

    const handleClick = () => {

        if(onClick){
            onClick();
            return;
        }
        if(data.url){
            history.push(data.url);
        }
    }

    return (
        <MenuButtonWrapper img={data.img} onClick={handleClick}>
            <TitleWrapper>
            {t(data.title).toUpperCase()}
            </TitleWrapper>
        </MenuButtonWrapper>
    );
}

const TitleWrapper = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    align-text : center; 
    text-align : center;
    max-width : 80%;

    background-color : rgba(0,0,0,0.6);
    padding : 0.8em 1.2em;
    border-radius : 100px;
`;

const MenuButtonWrapper = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;

    color : white;

    background : center center;
    background-color : white;
    background-image : url(${props => props.img});
    background-size : cover;

    cursor : pointer;
`;
 
export default MenuButton;