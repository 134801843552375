import React from 'react';
import ImageSlider from '../components/ImageSlider';
import styled from 'styled-components';
import galleryImg from '../assets/img/hotel/gallery.gif';
import reccImg from '../assets/img/hotel/recomendations.gif';
import { hotelMock } from '../constants/mock';

const Explore = ({ data, t }) => {
    const { media } = data;
    return (
        <div>
            <Title>
                <Titleicon src={galleryImg} alt="Gallery" />
                <span className="ml-1"> {t('GALERÍA')}</span>
            </Title>
            <ImageSlider images={hotelMock?.imageGallery} />

            {/* <Title>
                <Titleicon src={reccImg} alt="Recommendations" />
                {/* <span className="ml-1"> {t('RECOMENDACIONES')}</span> 
            </Title> */}
        </div>
    )
}

export default Explore;

const Titleicon = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 7%;
`;

const Title = styled.div`
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 15px auto;
    border-radius: 5px;
    padding: 10px;
    background: #FFF;
    box-shadow : 0px 2px 0px rgba(0, 0, 0, 0.05);
`;