import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store';
import { Router } from 'react-router-dom';
import history from'./history';
import './i18n';

import './css/index.less';
import './css/App.less';
import './assets/css/fontAwesome/fontawesome.css';
import './assets/css/fontAwesome/duotone.css';
import './assets/css/fontAwesome/light.min.css';
import './assets/css/fontAwesome/brands.min.css';
import './assets/css/fontAwesome/regular.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-awesome-button/dist/styles.css";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();