import React from 'react';

import pillowData from '../../../constants/pagesData/pillowData';
import { PageWrapper } from '../../styles';
import Card from '../../../components/Card';

const Pillow = ({t}) => {
    return (  
        <PageWrapper>
            {pillowData.map((data,index) => (
                <Card key={index} data={data} t={t}/>
            ))}
        </PageWrapper>
    );
}
 
export default Pillow;