export const colors = {
    "color-primary": "#E02078",
    "color-primary-100": "#FDD2D4",
    "color-primary-200": "#FBA5B3",
    "color-primary-300": "#F57798",
    "color-primary-400": "#EC548A",
    "color-primary-500": "#E02078",
    "color-primary-600": "#C01775",
    "color-primary-700": "#A1106F",
    "color-primary-800": "#810A64",
    "color-primary-900": "#6B065D",
    "color-primary-transparent-100": "rgba(224, 32, 120, 0.08)",
    "color-primary-transparent-200": "rgba(224, 32, 120, 0.16)",
    "color-primary-transparent-300": "rgba(224, 32, 120, 0.24)",
    "color-primary-transparent-400": "rgba(224, 32, 120, 0.32)",
    "color-primary-transparent-500": "rgba(224, 32, 120, 0.4)",
    "color-primary-transparent-600": "rgba(224, 32, 120, 0.48)",
    "color-success": "#5EE033",
    "color-success-100": "#EBFDD6",
    "color-success-200": "#D1FBAE",
    "color-success-300": "#B0F583",
    "color-success-400": "#8FEC63",
    "color-success-500": "#5EE033",
    "color-success-600": "#40C025",
    "color-success-700": "#28A119",
    "color-success-800": "#148110",
    "color-success-900": "#096B0D",
    "color-success-transparent-100": "rgba(94, 224, 51, 0.08)",
    "color-success-transparent-200": "rgba(94, 224, 51, 0.16)",
    "color-success-transparent-300": "rgba(94, 224, 51, 0.24)",
    "color-success-transparent-400": "rgba(94, 224, 51, 0.32)",
    "color-success-transparent-500": "rgba(94, 224, 51, 0.4)",
    "color-success-transparent-600": "rgba(94, 224, 51, 0.48)",
    "color-info": "#009BE2",
    "color-info-100": "#CAFAFD",
    "color-info-200": "#97F0FC",
    "color-info-300": "#62DBF6",
    "color-info-400": "#3BC1ED",
    "color-info-500": "#009BE2",
    "color-info-600": "#0078C2",
    "color-info-700": "#005AA2",
    "color-info-800": "#004083",
    "color-info-900": "#002D6C",
    "color-info-transparent-100": "rgba(0, 155, 226, 0.08)",
    "color-info-transparent-200": "rgba(0, 155, 226, 0.16)",
    "color-info-transparent-300": "rgba(0, 155, 226, 0.24)",
    "color-info-transparent-400": "rgba(0, 155, 226, 0.32)",
    "color-info-transparent-500": "rgba(0, 155, 226, 0.4)",
    "color-info-transparent-600": "rgba(0, 155, 226, 0.48)",
    "color-warning": "#F48211",
    "color-warning-100": "#FEF0CF",
    "color-warning-200": "#FDDC9F",
    "color-warning-300": "#FBC36F",
    "color-warning-400": "#F8AA4B",
    "color-warning-500": "#F48211",
    "color-warning-600": "#D1640C",
    "color-warning-700": "#AF4A08",
    "color-warning-800": "#8D3405",
    "color-warning-900": "#752503",
    "color-warning-transparent-100": "rgba(244, 130, 17, 0.08)",
    "color-warning-transparent-200": "rgba(244, 130, 17, 0.16)",
    "color-warning-transparent-300": "rgba(244, 130, 17, 0.24)",
    "color-warning-transparent-400": "rgba(244, 130, 17, 0.32)",
    "color-warning-transparent-500": "rgba(244, 130, 17, 0.4)",
    "color-warning-transparent-600": "rgba(244, 130, 17, 0.48)",
    "color-danger": "#F93167",
    "color-danger-100": "#FEDAD5",
    "color-danger-200": "#FEAEAC",
    "color-danger-300": "#FD838C",
    "color-danger-400": "#FB637E",
    "color-danger-500": "#F93167",
    "color-danger-600": "#D62365",
    "color-danger-700": "#B31860",
    "color-danger-800": "#900F57",
    "color-danger-900": "#770951",
    "color-danger-transparent-100": "rgba(249, 49, 103, 0.08)",
    "color-danger-transparent-200": "rgba(249, 49, 103, 0.16)",
    "color-danger-transparent-300": "rgba(249, 49, 103, 0.24)",
    "color-danger-transparent-400": "rgba(249, 49, 103, 0.32)",
    "color-danger-transparent-500": "rgba(249, 49, 103, 0.4)",
    "color-danger-transparent-600": "rgba(249, 49, 103, 0.48)"
  }