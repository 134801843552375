const consiergeData = {
  subtitle: 'Estamos a su entera disposición para:',
  textIsList: true,
  text: [
    'Aconsejarle sobre los mejores restaurantes en Palma y reservar mesa',
    'Conseguirle entradas para cualquier evento o espectáculo que desea ver',
    'Recomendarle los mejores museos de la isla',
    'Informarle  sobre los pueblos más maravillosos y sus mercadillos en Mallorca',
    'Enseñarle las mejores playas y calas escondidas',
    'Todo lo que usted pueda necesitar',
    'Puede contactar con nosotros marcando el 899 desde el teléfono de su habitación',
  ],
};

export default consiergeData;
