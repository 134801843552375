import { uniqBy, find, filter, isEmpty } from 'lodash';
import { categories } from '../../constants/categories';

export const getCats = cart => {
    const output = [];
    const auxCart = uniqBy(cart, "tipoPlato");
    
    if (!isEmpty(auxCart)) {
        auxCart.map(cat => output.push(
            {
                id: cat.tipoPlato,
                name: getCatName(cat),
                img: getCatBg(cat)
            }
        ))
    }
    return output
}
export const getCatName = category => {
    return find(categories, { 'id': category.tipoPlato })?.Descripcion.toUpperCase();
}

export const getCatBg = category => {
    return find(categories, { 'id': category.tipoPlato })?.img;
}

export const getCatPlates = (cart, category) => {
    return filter(cart, { "tipoPlato": category.id });
}