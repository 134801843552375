import React from 'react';
import styled from 'styled-components';
import { PageWrapper } from '../styles';
import performancesData from '../../constants/pagesData/performancesData';
import Card from '../../components/Card';

const Performances = ({t}) => {
    return (
        <PerformancesWrapper>
            {performancesData.map((data,index) => 
                <Card data={data} t={t} key={index}/>
            )}
            
        </PerformancesWrapper>
    );
}

const PerformancesWrapper = styled(PageWrapper)`
`;
 
export default Performances;