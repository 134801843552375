import React from 'react';
import { List, Tag } from 'antd';
import Icon from 'react-eva-icons';
import styled from 'styled-components';

const ContactCardWidget = ({ contact, t }) => {

    return (
        <ContactList size="large" className="mt-3">
            <ContactItem className="px-2">
                <List.Item.Meta className="m-0 d-flex align-items-center capitalize"
                    avatar={<Icon name="pin-outline" fill="#313130" size="medium" />}
                    title={contact?.adress?.toLowerCase()} />
            </ContactItem>
            <ContactItem extra={<Tag><a href={`tel:${contact.phone}`}>{t('Llamar')}</a></Tag>} className="px-2">
                <List.Item.Meta className="m-0 d-flex align-items-center"
                    avatar={<Icon name="phone-outline" fill="#313130" size="medium" />}
                    title={contact?.phone} />
            </ContactItem>
            <ContactItem extra={<Tag><a href={`mailto:${contact.email}`}>{t('Enviar')}</a></Tag>} className="px-2">
                <List.Item.Meta className="m-0 d-flex align-items-center"
                    avatar={<Icon name="email-outline" fill="#313130" size="medium" />}
                    title={contact?.email} />
            </ContactItem>
        </ContactList>
    )
}

export default ContactCardWidget;

const ContactList = styled(List)`
    margin-top: .5rem;
    border-bottom: none!important;
`;
const ContactItem = styled(List.Item)`
    background-color: #FFF;
    margin: 10px 10px;
    border-radius: 5px;
    border-bottom: 1px solid #2222222b !important;
`