import React from 'react';

import treatmentData from '../../../constants/pagesData/treatmentData';
import Card from '../../../components/Card';
import { PageWrapper } from '../../styles';

const Treatment = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={treatmentData} t={t}/>
        </PageWrapper>
    );
}
 
export default Treatment;