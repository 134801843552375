import fitnessPDF from '../../assets/files/fitness/fitness.pdf';

// const fitnessData = [
//     {
//         subtitle : "Lunes",
//         text : ["Yoga : 9:00 - 20:30"],
//     },
//     {
//         subtitle : "Martes",
//         text : ["Aquagym : 18:20 - 19:30","Bodyfit : 19:30 - 20:30"],
//     },
//     {
//         subtitle : "Miércoles",
//         text : ["GAP: 18:20 - 19:30","Zumba: 19:30 - 20:30"],
//     },
//     {
//         subtitle : "Jueves",
//         text : ["Aquagym : 18:20 - 19:30","Bodyfit : 19:30 - 20:30"],
//     },
//     {
//         subtitle : "Viernes",
//         text : ["Yoga : 18:30 - 19:30"],
//     }
// ]

const fitnessData = {
    pdfFile : fitnessPDF
}

export default fitnessData;