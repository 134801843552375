import logo from '../assets/img/logo_hotel.png';
import bg from '../assets/img/bg_d.jpg';
export const hotelMock = {
    id: 1,
    name: "GRPO Valpariso Palace & Spa",
    moto: "'Urban Resort' de 5 estrellas",
    logo: logo,
    bg: bg,
    services: [],
    imageGallery: [
        "https://www.gprovalparaiso.com/backoffice/images/350-intimacy-terrace.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/202-dsc7096.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/420-lobby-2019-4.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/262-spa-reception-tratmintos-1.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/259-spa-cabina2.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/263-tumbonas-calientes.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/261-spa-piscina-interior-3.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/356-piscina-interior.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/260-spa-hammam.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/257-gym.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/218-valparaiso-097.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/213-bistro.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/417-infinity-21.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/410-dao-garden-night-view.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/224-demi-suite-fruta.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/226-bed-cinema-dos.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/346-deluxe-23.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/231-aquamarine-salon1.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/236-jade-agate-tourquoise-dormitorio-2.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/243-rubin-closet.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/248-gran-bano-706-saphire.jpg",
        "https://www.gprovalparaiso.com/backoffice/images/406-demi-suite-8.jpg",
    ],
    contact: {
        city: "Palma",
        address: "Carrer de Francesc Vidal I Sureda, 23, 07015 Palma, Illes Balears",
        phone: "+34 971 400 300",
        email: "valparaiso@gprovalparaiso.com"
    },
    coordinates: {
        lat: 39.5555556,
        lng: 2.6172557
    }
}