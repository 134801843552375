import formentor from '../../assets/img/hotelImages/excursions/formentor.jpg';
import market from '../../assets/img/hotelImages/excursions/market.jpg';
import boat from '../../assets/img/hotelImages/excursions/boat.jpg';
import train from '../../assets/img/hotelImages/excursions/train.jpg';
import citysightseeing from '../../assets/img/hotelImages/excursions/citysightseeing.jpg';
import sonamar from '../../assets/img/hotelImages/excursions/sonamar.jpg'

const excursionsData = {
    title : "Queremos darle la oportunidad para explorar los tesoros de nuestra maravillosa isla.",
    content : [
        {
            subtitle : "Formentor y Mercado",
            img : [
                formentor,
                market
            ]
        },
        {
            subtitle : "Vuelta a la isla",
            text : [
                "Tren + Tranvia + Barca"
            ],
            img : [
                train,
                boat
            ]
        },
        {
            subtitle : "Palma Citysightseeing",
            img : citysightseeing
        },
        {
            subtitle : "Son Amar Cena y espectáculo",
            img : sonamar
        }
    ]
};

export default excursionsData;