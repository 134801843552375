import React from 'react';
import { PageWrapper } from '../styles';
import { WhiteBoard } from '../../components/styles';
import youDoData from '../../constants/pagesData/youDoData';

const WhatYouDo = ({t}) => {
    return (  
        <PageWrapper>
            {youDoData.text.map(text =>
                <WhiteBoard key={text}>{t(text)}</WhiteBoard>
            )}
        </PageWrapper>
    );
}
 
export default WhatYouDo;