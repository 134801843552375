import React from 'react';

import fitnessData from '../../../constants/pagesData/fitnessData';
// import Card from '../../../components/Card';
import { PageWrapper } from '../../styles';
import PDFViewer from '../../../components/PDFViewer';

const FitCourses = ({t}) => {
    return ( 
        <PageWrapper>
            {/* {fitnessData.map((data,index) => 
                <Card key={index} data={data} t={t}/>
            )} */}
            <PDFViewer url={fitnessData.pdfFile}/>
        </PageWrapper>
    );
}
 
export default FitCourses;