import React from 'react';

import WelcomeHSPA from '../../pages/InfoSubPages/HSPASubPages/WelcomeHSPA';
import ReceptionHSPA from '../../pages/InfoSubPages/HSPASubPages/ReceptionHSPA';
import Treatment from '../../pages/InfoSubPages/HSPASubPages/Treatment';
import Boutique from '../../pages/InfoSubPages/HSPASubPages/Boutique';
import SaunaArea from '../../pages/InfoSubPages/HSPASubPages/SaunaArea';
import Hairdresser from '../../pages/InfoSubPages/HSPASubPages/Hairdresser';
import Gym from '../../pages/InfoSubPages/HSPASubPages/Gym';
import WaterArea from '../../pages/InfoSubPages/HSPASubPages/WaterArea';
import FitCourses from '../../pages/InfoSubPages/HSPASubPages/FitCourses';

// images
import welcome from '../../assets/img/hotelImages/menuButtons/hspa/welcome.jpg';
import reception from '../../assets/img/hotelImages/menuButtons/hspa/reception.jpg';
import treatment from '../../assets/img/hotelImages/menuButtons/hspa/treatment.jpg';
import boutique from '../../assets/img/hotelImages/menuButtons/hspa/boutique.jpg';
import sauna from '../../assets/img/hotelImages/menuButtons/hspa/sauna.jpg';
import hairdresser from '../../assets/img/hotelImages/menuButtons/hspa/hairdresser.jpg';
import gym from '../../assets/img/hotelImages/menuButtons/hspa/gym.jpg';
import water from '../../assets/img/hotelImages/menuButtons/hspa/water.jpg';
import fitness from '../../assets/img/hotelImages/menuButtons/hspa/fitness.jpg';


const HSPAData = [
    {
        title : "HSPA Bienvenida",
        url : '/info/hspa/welcome',
        page : WelcomeHSPA,
        img : welcome,
    },
    {
        title : "Recepción del HSPA",
        url : '/info/hspa/reception',
        page : ReceptionHSPA,
        img :  reception,
    },
    {
        title : "Salas de tratamiento",
        url : '/info/hspa/treatment',
        page : Treatment,
        img : treatment,
    },
    {
        title : "HSPA Boutique",
        url : '/info/hspa/boutique',
        page : Boutique,
        img : boutique,
    },
    {
        title : "Zona Termal",
        url : '/info/hspa/termal-area',
        page : SaunaArea,
        img : sauna,
    },
    {
        title : "HSPA Peluqueria",
        url : '/info/hspa/hairdresser',
        page : Hairdresser,
        img : hairdresser,
    },
    {
        title : "HSPA Gimnasio",
        url : '/info/hspa/gym',
        page : Gym ,
        img : gym,
    },
    {
        title : "Zona de Aguas",
        url : '/info/hspa/water-area',
        page : WaterArea,
        img : water,
    },
    {
        title : "Clases dirigidas",
        url : '/info/hspa/fit-courses',
        page : FitCourses,
        img : fitness,
    },
];

export default HSPAData;
