import Excursions from '../../pages/InfoSubPages/ReceptionSubPages/Excursions';
import Pillow from '../../pages/InfoSubPages/ReceptionSubPages/Pillow';
import Consierge from '../../pages/InfoSubPages/ReceptionSubPages/Consierge';
import Bookings from '../../pages/InfoSubPages/ReceptionSubPages/Bookings';
import Confirmation from '../../pages/InfoSubPages/ReceptionSubPages/Confirmation';
import Golf from '../../pages/InfoSubPages/ReceptionSubPages/Golf';
import Transfer from '../../pages/InfoSubPages/ReceptionSubPages/Transfer';
import Flowers from '../../pages/InfoSubPages/ReceptionSubPages/Flowers';

// images
import consierge from '../../assets/img/hotelImages/menuButtons/reception/consierge.jpg';
import pillow from '../../assets/img/hotelImages/menuButtons/reception/pillow.jpg';
import excursions from '../../assets/img/hotelImages/menuButtons/reception/excursions.jpg';
import bookings from '../../assets/img/hotelImages/menuButtons/reception/bookings.jpg';
import confirmation from '../../assets/img/hotelImages/menuButtons/reception/confirmation.jpg';
import golf from '../../assets/img/hotelImages/menuButtons/reception/golf.jpg';
import transfer from '../../assets/img/hotelImages/menuButtons/reception/transfer.jpg';
import flowers from '../../assets/img/hotelImages/menuButtons/reception/flowers.jpg';

const receptionData = [
    {
        title : "Conserjería",
        url : "/info/reception/consierge",
        page : Consierge,
        img : consierge,
    },
    {
        title : "Almohada",
        url : "/info/reception/pillow",
        page : Pillow,
        img : pillow,
    },
    {
        title : "Excursiones",
        url : "/info/reception/excursions",
        page : Excursions,
        img : excursions,
    },
    {
        title : "Alquileres",
        url : "/info/reception/bookings",
        page : Bookings,
        img : bookings,
    },
    {
        title : "Confirmación",
        url : "/info/reception/confirmation",
        page : Confirmation,
        img : confirmation,
    },
    {
        title : "Golf",
        url : "/info/reception/golf",
        page : Golf,
        img : golf,
    },
    {
        title : "Transfer",
        url : "/info/reception/transfer",
        page : Transfer,
        img : transfer,
    },
    {
        title : "Flores",
        url : "/info/reception/flowers",
        page : Flowers,
        img : flowers,
    },
];

export default receptionData;