import React from 'react';
import Icon from 'react-eva-icons';

export const routes = [
    {
        id: 'home',
        to: '/',
        slug: 'Inicio',
        title: 'INICIO',
        icon: <Icon name="home-outline" size="medium" fill="#313130" />,
        color: "#313130",
        bgColor: "#E1EFEA"
    },
    {
        id: 'explore',
        to: '/explore',
        slug: 'Explorar',
        title: 'EXPLORAR',
        icon: <Icon name="globe-outline" size="medium" fill="#313130" />,
        color: "#313130",
        bgColor: "#E1EFEA"
    },
    {
        id: 'contact',
        to: '/contact',
        slug: 'Contacto',
        title: 'CONTACTO',
        icon: <Icon name="phone-call-outline" size="medium" fill="#313130" />,
        color: "#313130",
        bgColor: "#E1EFEA"
    }
]