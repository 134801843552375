import styled from 'styled-components';

export const WhiteBoard = styled.div`
  background: white;
  border-radius: 5px;
  padding: 20px;

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const DocumentWrapper = styled.div`
  position: relative;

  display: flex;
  // background: #222222;
  padding: 20px;
  height: 100%;
  overflow-y: auto;

  .react-pdf__Document {
    margin: 0 auto;
  }

  .react-pdf__Page {
    margin-bottom: 10px;
  }
`;

export const AddWrapper = styled.div``;

export const RestWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  font-size: 1rem;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  border-radius: 5px;

  left: 1rem;
  bottom: 1rem;

  width: 5rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);

  overflow: hidden;

  & > div {
    z-index: 1;
    width: fit-content;
    height: fit-content;
    margin: 10px;
  }
`;
