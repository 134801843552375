import React from 'react';
import styled from 'styled-components';
import { colors } from './colors';

export const CLOSE_ICON = <i className="fal fa-times-hexagon mr-1"></i>
export const CLOSE_ICON_II = <i className="fad fa-chevron-double-down"></i>

export const ORDER_TITLE_ICON = <i className="fad fa-clipboard-list-check" />;
export const TABLE_ICON = <i className="fad fa-game-board-alt" />
export const ADD_ICON = <i className="far fa-plus"/>;
export const REST_ICON = <i className="far fa-minus"/>
export const ADD_ICON_SQUARE = <i className="fad fa-plus-square" />;
export const ADD_ICON_CIRCLE = <i className="fad fa-plus-circle" />;
export const REST_ICON_CIRCLE = <i className="fad fa-minus-circle" />;
export const EDIT_ICON = <i className="far fa-comment-alt-edit"></i>;
export const USER_EDIT = <i className="fal fa-user-edit mx-1"></i>
export const DELETE_ICON = <i className="fad fa-times-square" />;
export const TRASH_ICON = <i className="fad fa-trash-alt" />;
export const LIST_ICON = <i className="fad fa-list" />;
export const PASSWORD_ICON = <i className="fad fa-unlock-alt" />;
export const CONFIG_USERS_ICON = <i className="fad fa-users-cog" />;
export const SAVE_ICON = <i className="fad fa-save" />;
export const HOME_ICON = <i className="fad fa-home" />;
export const GO_BACK_ICON = <i className="fad fa-chevron-square-left"></i>;

export const PLUS_ICON = <i className="fal fa-layer-group" />

export const PAY_ICON = <i className="fal fa-money-bill-alt mr-1"></i>;
export const CART_ICON = <i className="fal fa-cart-plus mr-1"></i>;

export const LOCKED_ICON = <i className="fad fa-lock-alt" />;
export const UNLOCKED_ICON = <i className="fad fa-lock-open-alt" />;

const SUCCESSCOLOR = styled.i` color: ${colors["color-success"]}`;
const WARNINGCOLOR = styled.i` color: ${colors["color-warning"]}`;

export const SAVED_SUCCESS = <SUCCESSCOLOR className="fad fa-box-check" />;
export const SAVED_WARNING = <WARNINGCOLOR className="fad fa-exclamation-square" />;

export const PLATE_NAME_ICON = <i className="fad fa-align-left" />;
export const PLATE_NATURE_ICON = <i className="fad fa-list-ul" />;
export const PLATE_PRICE_ICON = <i className="fad fa-usd-square" />;
export const PLATE_CAT_ICON = <i className="fad fa-tag" />;

export const CASH_ICON = <i className="fad fa-money-bill"></i>;
export const CREDIT_CARD_ICON = <i className="fad fa-credit-card"></i>;
export const PAYPAL_ICON = <i className="fab fa-paypal"></i>;
export const ROOM_PAYMENT_ICON = <i className="fad fa-h-square"></i>;