import React, { Fragment } from 'react';
import { isArray } from 'lodash';
import { getLanguage } from '../LanguageSelector';
import { List, Avatar, Tooltip, Spin } from 'antd';
import { allergens } from '../../constants/allergens';
import './css/ProductList.css'

const avatar_style = {
    width: 55,
    height: 55
}

const img_st = {
    maxHeight: '100%',
    maxWidth: '100%'
}
const ProductList = ({ product_list, hidePhoto, t}) => {

    const lng = getLanguage() || 'en';

    //RENDERIZA LOS ICONOS DE LOS ALERGENOS
    const allergensRender = plate => {
        const output = [];
        if (plate?.alergenosPorPlato) {
            plate.alergenosPorPlato.map((al, index) =>
                output.push(<Tooltip title={t(allergens[al].label)}
                    trigger={['click', 'hover']} key={`al-toltp-key-${index}`}>
                    <img style={img_st}
                        src={allergens[al].icon}
                        alt={t(allergens[al].label)}
                        key={`al-key-${index}`} className="mr-2" />
                </Tooltip>))
        }
        return output;
    }

    const getTitle = plato => {
        if(lng === 'es'){
            return plato.descripcion;
        }
        if(lng !== 'es'){
            const index = plato.traducciones.findIndex(trans => trans.idioma?.toLowerCase() === lng);
            if(index === -1){
                // try to translate from translation file
                return t(plato.descripcion);
            }
            return plato.traducciones[index].traduccion;
        }
    }

    return (
        <Fragment>
            {isArray(product_list) ?
                <Fragment>
                    <List itemLayout="horizontal" dataSource={product_list}
                        renderItem={plato => (
                            <Fragment>
                                <List.Item className="px-4 w-100" actions={[`${plato.precio}€`]} >
                                    <List.Item.Meta avatar={!hidePhoto
                                        ? <Avatar style={avatar_style} size="large" src={`data:image/jpeg;base64,${plato.imagenPlato}`} shape="square" />
                                        : null}
                                        title={getTitle(plato)}
                                        description={
                                            <div style={{ display: 'flex', width: 14, height: 14 }}>
                                                {allergensRender(plato)}
                                            </div>} className="text-left" />
                                </List.Item>
                            </Fragment>
                        )} />
                </Fragment>
                : <Spin />
            }
        </Fragment>
    )
};

export default ProductList;