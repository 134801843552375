import React from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { PageWrapper } from '../pages/styles';

const Tabs = ({tabs, t}) => {

    const history = useHistory();
    const { tab : active} = useParams();

    const onClickTab = (tabID) => {
        if(tabID === Number(active)) return;
        history.replace(`/roomservice/${tabID}`);
    }

    return (
        <TabsWrapper num={tabs?.length}>
            {tabs?.map(tab => 
                <Tab tab={tab} active={Number(active) === tab.tabNum} key={tab.tabNum} onClick={onClickTab} t={t}/>
            )}
        </TabsWrapper>
    );
}

const TabsWrapper = styled(PageWrapper)`
    display : grid;
    grid-template-columns : repeat(${props => props.num}, 1fr);
    grid-template-rows : 4rem;
    gap : 16px;
    width : 100%;
    font-weight : 600;

    & > .selected {
        background-color : white;
        box-shadow : 0px 2px 0px rgba(0, 0, 0, 0.05);
    }
`;

const Tab = ({active,tab, onClick, t}) => {

    return (
        <TabWrapper className={active && 'selected'} onClick={() => onClick(tab.tabNum)}>
            {t(tab.title)}
        </TabWrapper>
    )
}

const TabWrapper = styled.div`
    display : flex;
    flex : 1;

    width : 100%;
    height : 100%;

    padding : 10px;

    justify-content : center;
    align-items : center;
    text-align : center;

    border-radius : 10px;

    box-shadow : inset 0 0 12px 0px rgba(49,49,48,0.04);
`;
 
export default Tabs;