import React from 'react';
import { PageWrapper } from '../styles';
import meetingsData from '../../constants/pagesData/meetingsData';
import Card from '../../components/Card';

const Meetings = ({t}) => {
    return (
        <PageWrapper>
            <Card data={meetingsData} t={t} />
        </PageWrapper>
    );
}
 
export default Meetings;