import React from 'react';

import hairdresserData from '../../../constants/pagesData/hairdresserData';
import Card from '../../../components/Card';
import { PageWrapper } from '../../styles';

const Hairdresser = ({t}) => {
    return ( 
        <PageWrapper>
            <Card data={hairdresserData} t={t}/>
        </PageWrapper>
    );
}
 
export default Hairdresser;