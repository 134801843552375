const youDoData = {
    text : [
        "Apagar luces innecesarias",
        "Pedir únicamente el cambio de sábanas cuando sea necesario",
        "Hacer uso responsable de las toallas del baño y de la piscina",
        "Ducharse en lugar de bañarse",
        "Cerrar el grifo al lavarse los dientes o al afeitarse",
        "Mantener una temperatura de confort en la habitación evitando temperaturas extremas"
    ]
}

export default youDoData;