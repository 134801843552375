import gym from '../../assets/img/hotelImages/menuButtons/hspa/gym.jpg';

const gymData = {
  text: [
    'El hotel ofrece un gimnasio con máquinas y pesas para realizar ejercicios físicos.',
    'Su uso no está permitido a menores de 16 años.',
    'Horario: 8.00 a 20.00',
  ],
  img: gym,
};

export default gymData;
