import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import MenuItemII from './MenuItemII';
import styled from 'styled-components';

const AppMenu = ({ t }) => {
    const history = useHistory();
    const {pathname} = useLocation();
    const [active, setActive] = useState(history.location.pathname);
    
    useEffect(() => {
        const index = routes.findIndex(route => route.to === pathname);
        
        if(index !== -1){
            setActive(pathname);
        }
        if(index === -1){
            setActive('/')
        }
    },[pathname])

    const goTo = path => {
        if (path) {
            history.push(path);
        }
    }
    return (
        <MenuWrapper>
            {
                routes.map(route =>
                    <MenuItemII value={route.to}
                        logo={route.icon}
                        text={t(route.slug)}
                        isActive={active === route.to}
                        goTo={goTo}
                        color={route.color}
                        backgroundColor={route.bgColor}
                        key={`route-key${route.to}`} />)
            }
        </MenuWrapper>
    )
}

export default AppMenu;

const MenuWrapper = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    box-shadow: 0px 10px 40px 0px #18236b33;
    z-index: 100;
`;