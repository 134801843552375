const pillowData = [
    {
        subtitle : "Plumas",
        text : [
            "Una almohada blanda y cálida."
        ]
    },
    {
        subtitle : "Tian",
        text : [
            "Almohada firme y gruesa, ideal si duerme de lado o boca arriba."
        ]
    },
    {
        subtitle : "Allerban",
        text : [
            "Una almohada saludable, perfecta para personas alérgicas."
        ]
    },
    {
        subtitle : "Heaven",
        text : [
            "Una almohada blanda y fina, para aquellos que prefieren un ligero apoyo. Recomendada para niños."
        ]
    },
    {
        subtitle : "Oasis",
        text : [
            "Si lo que desea es una almohada firme y alta esta es su opción. Proporciona un equilibrio entre solidez y comodidad."
        ]
    },
    {
        subtitle : "Butterfly",
        text : [
            "Recomendada para espalda y hombros"
        ]
    },
    {
        subtitle : "Cervical",
        text : [
            "Favorece el descanso de sus cervicales. Recomendado para leer o ver la televisión."
        ]
    },
    {
        subtitle : "Dalai",
        text : [
            "Almohada con dos diferentes niveles para un óptimo soporte de cuello y hombros."
        ]
    },
]

export default pillowData;