import carnes from './../assets/img/categories/carnes.jpg';
import pescados from './../assets/img/categories/pescados.jpg';
import ensaladas from './../assets/img/categories/ensaladas.jpg';
import entrantes from './../assets/img/categories/entrantes.jpg';
import postres from './../assets/img/categories/postres.jpg';
import pastas from './../assets/img/categories/pastas.jpg';
import pizzas from './../assets/img/categories/pizzas.jpg';
import refrescos from './../assets/img/categories/refrescos.jpg';
import vinos_tintos from './../assets/img/categories/vinos_tintos.jpg';
import vinos_blancos from './../assets/img/categories/vinos_blancos.jpg';
import vinos_rosados from './../assets/img/categories/vinos_rosados.jpg';
import agua from './../assets/img/categories/agua.jpg';
import champan_cava from './../assets/img/categories/champan_cava.jpg';
import cafe_te from './../assets/img/categories/cafe_te.jpg';
import licores from './../assets/img/categories/licores.jpg';
import otros from './../assets/img/categories/otros.jpg';
import tapas from './../assets/img/categories/tapas.jpg';
import bocadillos from './../assets/img/categories/bocadillos.jpg';
import helados from './../assets/img/categories/helados.jpg';
import sorbetes from './../assets/img/categories/sorbetes.jpg';
import sopas from './../assets/img/categories/sopas.jpg';
import cervezas from './../assets/img/categories/cervezas.jpg';
import aperitivos from './../assets/img/categories/aperitivos.jpg';
import sangria from './../assets/img/categories/sangria.jpg';
import cocktails from './../assets/img/categories/cocktails.jpg';
import gin from './../assets/img/categories/gin.jpg';
import vodka from './../assets/img/categories/vodka.jpg';
import rum from './../assets/img/categories/rum.jpg';
import whisky from './../assets/img/categories/whisky.jpg';
import brandy from './../assets/img/categories/brandy.jpg';
import mallorca from './../assets/img/categories/mallorca.jpg';
import orujo from './../assets/img/categories/orujo.jpg';


export const categoriesImages = [
    carnes,
    pescados,
    ensaladas,
    entrantes,
    postres,
    pastas,
    pizzas,
    refrescos,
    vinos_tintos,
    vinos_blancos,
    vinos_rosados,
    agua,
    champan_cava,
    cafe_te,
    licores,
    otros,
    tapas,
    bocadillos,
    helados,
    sorbetes,
    sopas,
    cervezas,
    aperitivos,
    sangria,
    cocktails,
    gin,
    vodka,
    rum,
    whisky,
    brandy, 
    mallorca,
    orujo
]

export const categories = [
    null,
    { id: 1, Descripcion: 'Carnes', path: "meats", img: carnes },
    { id: 2, Descripcion: 'Pescados', path: "fish", img: pescados },
    { id: 3, Descripcion: 'Ensaladas', path: "salads", img: ensaladas },
    { id: 4, Descripcion: 'Entrantes', path: "starters", img: entrantes },
    { id: 5, Descripcion: 'Postres', path: "desserts", img: postres },
    { id: 6, Descripcion: 'Pastas', path: "pasta", img: pastas },
    { id: 7, Descripcion: 'Pizzas', path: "pizza", img: pizzas },
    { id: 8, Descripcion: 'Refrescos', path: "refreshs", img: refrescos },
    { id: 9, Descripcion: 'Vinos tintos', path: "red_wine", img: vinos_tintos },
    { id: 10, Descripcion: 'Vinos blancos', path: "white_wine", img: vinos_blancos },
    { id: 11, Descripcion: 'Vinos rosados', path: "rose_wine", img: vinos_rosados },
    { id: 12, Descripcion: 'Agua', path: "water", img: agua },
    { id: 13, Descripcion: 'Champán y cava', path: "champagne_and_cava", img: champan_cava },
    { id: 14, Descripcion: 'Infusiones y cafés', path: "infunsions_and_coffee", img: cafe_te },
    { id: 15, Descripcion: 'Licores', path: "liquor", img: licores },
    { id: 16, Descripcion: 'Otros', path: "others", img: otros },
    { id: 17, Descripcion: 'Tapas', path: "tapas", img: tapas },
    { id: 18, Descripcion: 'Bocatas', path: "sandwiches", img: bocadillos },
    { id: 19, Descripcion: 'Helados', path: "icecreams", img: helados },
    { id: 20, Descripcion: 'Sorbetes', path: "sobertes", img: sorbetes },
    { id: 21, Descripcion: 'Cremas y sopas', path: "soups", img: sopas },
    { id: 23, Descripcion: 'Cervezas', path: 'beers', img: cervezas },
    { id: 24, Descripcion: 'Aperitivos', path: 'appetizers', img: aperitivos },
    { id: 27, Descripcion: 'Sangría', path: 'sangria', img: sangria },
    { id: 28, Descripcion: 'Cocktails', path: 'cockatails', img: cocktails },
    { id: 29, Descripcion: 'Ginebras', path: 'gins', img: gin },
    { id: 30, Descripcion: 'Vodkas', path: 'vodkas', img: vodka },
    { id: 31, Descripcion: 'Rones', path: 'rums', img: rum },
    { id: 32, Descripcion: 'Whiskies', path: 'whisky', img: whisky },
    { id: 33, Descripcion: 'Brandy', path: 'brandy', img: brandy },
    { id: 34, Descripcion: 'Bebidas típicas de Mallorca', path: 'mallorcas_drinks', img: mallorca },
    { id: 35, Descripcion: 'Orujos', path: 'pomace', img: orujo },
];

