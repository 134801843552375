import React from 'react';
import weDoData from '../../constants/pagesData/weDoData';
import { PageWrapper } from '../styles';

import { Collapse } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;

const WhatWeDo = ({t}) => {
    return (  
        <PageWrapper>
            <Collapse bordered={false} style={{backgroundColor : 'transparent'}}>
            {weDoData.map(data =>
                <MyPanel header={t(data.title)} key={data.title}>
                    <ul>
                    {data.text.map((text,index) =>
                        <li key={index}>{t(text)}</li>
                    )}
                    </ul>
                </MyPanel>
            )}
            </Collapse>
        </PageWrapper>
    );
}

const MyPanel = styled(Panel)`
    margin-bottom: 10px;
    background: white;
    border: 0px;
    border-radius: 5px !important;
    font-size : 1rem !important;

    box-shadow : 0px 2px 0px rgba(0, 0, 0, 0.05);
`;
 
export default WhatWeDo;