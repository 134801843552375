import Axios from "axios";
import { API_URL } from "../constants/api";
import { SET_ESTABLISHMENT_DATA } from "../constants";
import history from "../history";
import { fetchMenuData } from "./menuActions";
import { fetchMapData } from "./mapActions";

export const fetchEstablishmentData = (ID) => {
  return async (dispatch) => {
    try {
      const estabID = atob(ID);
      const response = await Axios.get(
        `${API_URL}/establecimiento/get/${estabID}`
      )
        .then((response) => {
          dispatch({
            type: SET_ESTABLISHMENT_DATA,
            payload: response.data,
          });
          dispatch(fetchMenuData(estabID));
          dispatch(fetchMapData(response.data));
          history.push("/");
          return response.status;
        })
        .catch((err) => {
          console.log(err);
          return response.status;
        });
      return response;
    } catch (error) {
      console.log("fetchEstablishmentData -> error", error);
    }
  };
};
