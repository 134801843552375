const confirmationData = {
    subtitle :"Estaremos encantados de ayudarle con su vuelo :",
    text : [
        "Reservas",
        "Check-In Online",
        "Reserva de asiento",
        "Confirmación"
    ],
    textIsList : true,
};

export default confirmationData;